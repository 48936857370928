import React from 'react';

import './AdvantageCard.scss';

type Props = {
    title: string,
    subtitle: string,
    iconType: 'key' | 'guard' | 'lock',
};

const AdvantageCard = ({ iconType, title, subtitle }: Props) => (
    <article className={`advantage-card advantage-card_${iconType}`}>
        <p className="text-white text-sm text-bold advantage-card__title">{title}</p>
        <p className="text-white text-xs">{subtitle}</p>
    </article>
);

export default AdvantageCard;