import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import './WhatTheySayCarousel.scss';

const WhatTheySayCarousel = () => (
    <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={8}
        isPlaying={true}
        visibleSlides={1}
        infinite={true}
        isIntrinsicHeight={true}
        className="what-they-say-carousel"
    >
        <Slider>
            <Slide index={0} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'It is extremely helpful to Honeywell cybersecurity team that you have shared ISSP Labs unique expertise regarding cyber-attacks investigation.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Glenn Berube
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        General Manger, Connected Solutions, Honeywell International
                    </p>
                </div>
            </Slide>
            <Slide index={1} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'We wanted to express our profound gratitude for your support in making our Global Forum on Strategic Communications (StratCom), hosted at Washington, DC a great success. Your participation in the Solving Cyber Threats to Critical Infrastructure workshop added an invaluable perspective.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Geysha Gonzalez
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Deputy Director, Eurasia Center, Atlantic Council
                    </p>
                </div>
            </Slide>
            <Slide index={2} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'Information Systems Security Partners has become the go-to firm for victims of Ukraine's cyberwar.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        WIRED
                    </p>
                </div>
            </Slide>
            <Slide index={3} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'ThreatSCALE model for analysis of cyber-attacks that you've developed is a very interesting approach to investigating and preventing future APT attacks. Thank you for sharing this model and for the engaging dialogue with the lab.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Lori Glover, J.D.
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Managing Director, Alliances, Executive Director, MIT Computer Science and Artificial Intelligence Laboratory
                    </p>
                </div>
            </Slide>
            <Slide index={4} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'In 2015 the U.S. Department of Justice ICITAP Project Office has had the opportunity to work closely with ISSP in testing and interviewing of applicants for Ukraine's new Cyber Police. Thanks in part to the hard work of ISSP, police reform is moving ahead in Ukraine.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Robert P. Peacock
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Project Director, Regional Program Office, U.S. Department of Justice/ICITAP
                    </p>
                </div>
            </Slide>
            <Slide index={5} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'We recognize the important work of ISSP in enhancing the security of critical  infrastructures around the globe.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Michael Assante
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Director, Industrial Control Systems, The SANS Institute
                    </p>
                </div>
            </Slide>
            <Slide index={6} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'On behalf of The Aspen Institute, thank you for speaking at our Cambridge Cyber Summit earlier this month. We are grateful to you for sharing your wisdom and experience and we know attendees truly valued participating in the event.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        John P. Carling
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Chair, Cybersecurity & Technology Program, The Aspen Institute
                    </p>
                </div>
            </Slide>
            <Slide index={7} innerClassName="what-they-say-carousel__slide-inner">
                <div className="what-they-say-carousel__slide">
                    <q className="text-md text-bold what-they-say-carousel__quote">
                        'On behalf of all of us here at the Atlantic Council's Eurasia Center, we want to express our profound gratitude for your support in putting on our conference, Russia's Cyber Operations in Ukraine and Beyond. Your participation as a speaker added an invaluable perspective to the discussion.'
                    </q>
                    <p className="text-xs text-bold text-indigo what-they-say-carousel__author">
                        Ambassador John Herbst
                    </p>
                    <p className="text-xs text-indigo what-they-say-carousel__organization">
                        Director, Eurasia Center, Atlantic Council
                    </p>
                </div>
            </Slide>
        </Slider>
        <ButtonBack className="what-they-say-carousel__button what-they-say-carousel__button_back"> </ButtonBack>
        <ButtonNext className="what-they-say-carousel__button what-they-say-carousel__button_next"> </ButtonNext>
    </CarouselProvider>
);

export default WhatTheySayCarousel;