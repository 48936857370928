import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import WhoWillTakeCard from './WhoWillTakeCard';

import './WhoWillTake.scss'

const WhoWillTake = () => (
    <div className="who-will-take">
        <Grid>
            <Row>
                <Col xs={12} md={8}>
                    <p className="text-lg text-bold who-will-take__title">
                        Who will take care of your cybersecurity?
                    </p>
                </Col>
                <Col xs={12}>
                    <p className="text-sm who-will-take__subtitle">
                        Experts serving customers across 3 continents and 8 time zones who have:
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="who-will-take__cards-container">
                        <div className="who-will-take__card who-will-take__card_1">
                            <WhoWillTakeCard
                                sky
                                text="15 years of experience providing managed security services"
                            />
                        </div>
                        <div className="who-will-take__card who-will-take__card_2">
                            <WhoWillTakeCard
                                text="Experience investigating state-sponsored attacks against critical infrastructure (and other hacks)"
                            />
                        </div>
                        <div className="who-will-take__card who-will-take__card_3">
                            <WhoWillTakeCard
                                text="Practical knowledge from working on the frontlines of modern cyber war in Eastern Europe"
                            />
                        </div>
                        <div className="who-will-take__card who-will-take__card_video">
                            <div className="who-will-take__video">
                                <iframe
                                    loading="lazy"
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight={0}
                                    marginWidth={0}
                                    src="https://www.youtube.com/embed/Sbeuo1Fde0c?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
                                />
                            </div>
                        </div>
                        <div className="who-will-take__card who-will-take__card_4">
                            <WhoWillTakeCard
                                text="Expertise in discovering hidden behavioral anomalies and detecting threats early"
                            />
                        </div>
                        <div className="who-will-take__card who-will-take__card_5">
                            <WhoWillTakeCard
                                text="The support of experts at the ISSP Labs and Research Center"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Grid>
    </div>
);

export default WhoWillTake;