import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import FooterForm from './FooterForm';

import './Footer.scss'

const Footer = () => (
    <div className="footer" id="contactUs">
        <Grid className="footer__content">
            <Row>
                <Col xs={12}>
                    <p className="text-lg text-bold text-white footer__title">
                        We are here to help
                    </p>
                </Col>
                <Col xs={12}>
                    <p className="text-sm text-white">
                        Please get in touch by completing the form or calling one of our offices listed below.
                    </p>
                </Col>
                <Col xs={12}>
                    <div className="footer__form-wrapper">
                        <FooterForm />
                    </div>
                </Col>
            </Row>
            <Row className="footer__contacts">
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Toronto
                    </p>
                    <p className="text-xs text-white">
                        Suite 2201, 250 Yonge St.
                        <br />
                        Toronto, ON M5B 2L7 Canada
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +1-647-361-5221">+1 647 361 5221</a>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +1-800-573-0922">+1 800 573 0922<span className="text-xs text-white text-thin"> (toll-free)</span></a>

                    <a className="text-white text-xs footer__contacts-email" href="mailto: ca@issp.com"> c a @ i s s p . c o m</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Vancouver
                    </p>
                    <p className="text-xs text-white">
                        Suite 2600, Three Bentall Centre
                        <br />
                        595 Burrard st., PO Box 49314
                        <br />
                        Vancouver BC V7X 1L3 Canada
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +1-289-968-4454">+1 289 968 4454</a>
                    <a className="text-white text-xs footer__contacts-email" href="mailto: ca@issp.com"> c a @ i s s p . c o m</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Washington DC
                    </p>
                    <p className="text-xs text-white">
                        1300 I Street NW
                        <br />
                        Suite 400E, Washington
                        <br />
                        District of Columbia, 20005
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +1-202-749-8432">+1 202 749 8432</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Wrocław
                    </p>
                    <p className="text-xs text-white">
                        1 Grabarska st., 50-079 Wrocław,
                        <br />
                        Poland
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +4-871-747-8705">+48 71 747 8705</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Tbilisi
                    </p>
                    <p className="text-xs text-white">
                        33b Ilia Chavchavadze ave,
                        <br />
                        0179, Tbilisi, Georgia
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +995-32-224-0366">+995 32 224 0366</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Kyiv
                    </p>
                    <p className="text-xs text-white">
                        10/14 Radyscheva St., Kyiv
                        <br />
                        Ukraine, 03124
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +380-44-594-8018">+380 44 594 8018</a>
                </Col>
                <Col xs={6} md={4} className="footer__contact-item">
                    <p className="text-sm text-white text-bold footer__contact-item-title">
                        Almaty
                    </p>
                    <p className="text-xs text-white">
                        808V, 165B Shevchenko St, 050009, Almaty,
                        <br />
                        Kazakhstan
                    </p>
                    <a className="text-white text-xs text-bold footer__contacts-phone" href="tel: +7-727-341-0024">+7 727 341 0024</a>
                </Col>
            </Row>
            <div className="footer__copyrights text-xs text-violet-light">Copyright © {new Date().getFullYear()} ISSP. All rights reserved.</div>
        </Grid>
    </div>
);

export default Footer;