import React from 'react';

import './WhoWillTakeCard.scss';

type Props = {
    sky?: boolean,
    text: string,
};

const WhoWillTakeCard = ({ text, sky }: Props) => (
    <article className={`who-will-take-card ${sky ? 'who-will-take-card_sky' : ''}`}>
        <p className={`text-xs ${sky ? 'text-indigo' : 'text-white'}`}>{text}</p>
    </article>
);

export default WhoWillTakeCard;