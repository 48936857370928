import React from 'react';

import Navigation from '../Navigation/Navigation';
import Header from '../Header/Header';
import VitalAreas from '../VitalAreas/VitalAreas';
import SocCovers from '../SocCovers/SocCovers';
import WhatYouGet from '../WhatYouGet/WhatYouGet';
import WhyTheTailored from '../WhyTheTailored/WhyTheTailored';
import RecentRealLife from '../RecentRealLife/RecentRealLife';
import HowExactly from '../HowExactly/HowExactly';
import WhoWillTake from '../WhoWillTake/WhoWillTake';
import CyberThreats from '../CyberThreats/CyberThreats';
import WhatTheySay from '../WhatTheySay/WhatTheySay';
import Footer from '../Footer/Footer';

const App = () => (
    <>
        <Navigation />
        <header>
            <Header />
        </header>
        <main>
            <section>
                <VitalAreas />
            </section>
            <section>
                <SocCovers />
            </section>
            <section>
                <WhatYouGet />
            </section>
            <section>
                <WhyTheTailored />
            </section>
            <section>
                <RecentRealLife />
            </section>
            <section>
                <HowExactly />
            </section>
            <section>
                <WhoWillTake />
            </section>
            <section>
                <CyberThreats />
            </section>
            <section>
                <WhatTheySay />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
    </>
);

export default App;