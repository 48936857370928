import React, { useState } from 'react';

import './Burger.scss';

type Props = {
    onClick: (isCollapsed: boolean) => void,
}

const Burger = ({ onClick }: Props) => {
    const [isActive, setMenuState] = useState(false);

    return (
        <button className={`hamburger hamburger--squeeze ${isActive ? 'is-active' : ''}`} type="button" onClick={() => {
            setMenuState(!isActive);
            onClick(isActive)
        }}>
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>
    );
};

export default Burger;