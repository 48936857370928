import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import WhatTheySayCarousel from './WhatTheySayCarousel';
import WhatTheySayTicker from './WhatTheySayTicker';

import './WhatTheySay.scss'

const WhatTheySay = () => (
    <div className="what-they-say">
        <Grid>
            <Row>
                <Col xs={12} md={8} mdOffset={2} lg={12} lgOffset={0}>
                    <p className="text-lg text-bold what-they-say__title">
                        What they say about us
                    </p>
                </Col>
                <Col xs={12}>
                    <WhatTheySayCarousel />
                </Col>
            </Row>
        </Grid>
        <WhatTheySayTicker />
    </div>
);

export default WhatTheySay;