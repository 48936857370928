import React from 'react';
import { render } from 'react-dom';
import SmoothScroll from 'smooth-scroll'

import App from './components/App/App';

new SmoothScroll('a[href*="#"]', {
	speed: 500,
	speedAsDuration: true
});

import './styles/index.scss';

render(<App />, document.getElementById('root'));