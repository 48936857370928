import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import RecentRealLifeCard from './RecentRealLifeCard';

import './RecentRealLife.scss'

const RecentRealLife = () => {
    const [readMoreContentShownWithCardNumber, setReadMoreContentShownWithCardNumber] = useState(0);

    return (
        <div className="recent-real-life">
            <Grid className="recent-real-life__content">
                <div className="recent-real-life__cage" />
                <Row>
                    <Col xs={12}>
                        <p className="text-lg text-bold text-white recent-real-life__title">
                            Recent real-life examples
                    </p>
                    </Col>
                    <Col xs={12}>
                        <p className="text-sm text-white recent-real-life__subtitle">
                            We respect your privacy and do not publicly expose our clients’ identities
                    </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <RecentRealLifeCard
                            cardNumber={1}
                            text="During onboarding in 2019, we learned that an IT startup in Central Europe cooperated with more than the average number of partners, sharing confidential personal data that brought value-added services to end users."
                            readMoreContent="While configuring the scope of our service, we included the related risks to the list of controls for threat hunting and incident detection. Additionally, we built a cybersecurity development roadmap including a list of recommended solutions to implement."
                            onReadMoreClick={setReadMoreContentShownWithCardNumber}
                            readMoreContentShown={readMoreContentShownWithCardNumber === 1}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <RecentRealLifeCard
                            cardNumber={2}
                            text="In 2021, while conducting the preliminary risk assessment of a startup in North America, we detected vital flaws in the network segmentation and overall architecture."
                            readMoreContent="Networks had been segmented in a way that was convenient for developers but was absolutely unsafe from the security perspective.&nbsp;The customer decided not to take this risk, and in the process of onboarding, we helped them adjust their network segmentation to comply with best practices."
                            onReadMoreClick={setReadMoreContentShownWithCardNumber}
                            readMoreContentShown={readMoreContentShownWithCardNumber === 2}
                        />
                    </Col>
                    <Col xs={12} md={6} mdOffset={6}>
                        <RecentRealLifeCard
                            cardNumber={3}
                            text="A smart devices manufacturer in Canada approached ISSP in late 2020. They had been outsourcing incident detection and threat hunting from an MSSP and had been hacked two times in the last six months."
                            readMoreContent="The MSSP seemed to be doing everything that was expected of them, detecting incidents and threats as agreed in the contract. But this wasn’t enough from the customer’s perspective.&nbsp;There was nobody to advise and guide them during onboarding, and no business or technical context was considered."
                            onReadMoreClick={setReadMoreContentShownWithCardNumber}
                            readMoreContentShown={readMoreContentShownWithCardNumber === 3}
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default RecentRealLife;