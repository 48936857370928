import React, { useState } from 'react';
import useGeolocation from 'react-ipgeolocation';

import Burger from '../Burger/Burger'

import './Navigation.scss';

const regionsListConfig = {
    CA: {
        text: 'CANADA',
        link: 'https://www.issp.ca/',
    },
    GE: {
        text: 'GEORGIA',
        link: 'https://www.ge.issp.com/',
    },
    KZ: {
        text: 'KAZAKHSTAN',
        link: 'https://www.kz.issp.com/',
    },
    PL: {
        text: 'POLAND',
        link: 'http://poland.issp.com/',
    },
    UA: {
        text: 'UKRAINE',
        link: 'https://www.ua.issp.com/',
    },
    GLOBAL: {
        text: 'GLOBAL',
        link: 'https://www.issp.com/',
    },
};

const navigationByCountryConfig = {
    GLOBAL: {
        services: 'https://www.issp.com/services-1',
        solutions: 'https://www.issp.com/solutions',
        capabilities: 'https://www.issp.com/capabilities',
        consultingAndIntegration: 'https://www.issp.com/consulting-integration',
        securityOperationsCenter: 'https://www.issp.com/security-operations-center',
        iSSPLabsAndResearch: 'https://www.issp.com/issp-labs',
        trainingCenter: 'https://www.issp.com/training-center',
        about: 'https://www.issp.com/about',
        missionAndValues: 'https://www.issp.com/mission-and-values',
        vendorCatalog: 'https://www.issp.com/our-partners',
        news: 'https://www.issp.com/in-the-news',
        country: regionsListConfig.GLOBAL,
    },
    CA: {
        services: 'https://www.issp.ca/services',
        solutions: 'https://www.issp.ca/solutions',
        capabilities: 'https://www.issp.ca/capabilities',
        consultingAndIntegration: 'https://www.issp.ca/consulting-integration',
        securityOperationsCenter: 'https://www.issp.ca/security-operations-center',
        iSSPLabsAndResearch: 'https://www.issp.ca/issp-labs',
        trainingCenter: 'https://www.issp.ca/training-center',
        about: 'https://www.issp.ca/about',
        missionAndValues: 'https://www.issp.ca/mission-and-values',
        news: 'https://blog.issp.ca/',
        country: regionsListConfig.CA,
    },
    UA: {
        services: 'https://www.issp.ua/poslugi',
        solutions: 'https://www.issp.ua/rishennya',
        capabilities: 'https://www.issp.ua/mozhlivosti',
        consultingAndIntegration: 'https://www.issp.ua/konsalting-ta-integraciya',
        securityOperationsCenter: 'https://www.issp.ua/centr-upravlinnya-bezpekoyu-soc',
        iSSPLabsAndResearch: 'https://www.issp.ua/laboratoriya-issp',
        trainingCenter: 'https://www.issp.ua/trening-centr',
        about: 'https://www.issp.ua/pro-nas',
        missionAndValues: 'https://www.issp.ua/misiya-i-cinnosti',
        leadershipTeam: 'https://www.issp.ua/liderska-komanda',
        vendorCatalog: 'https://www.issp.ua/katalog-vendoriv',
        news: 'https://www.issp.ua/novini',
        vacancies: 'https://www.issp.ua/vakansiyi',
        country: regionsListConfig.UA,
    },
    GE: {
        services: 'https://en.issp.ge/blank',
        solutions: 'https://www.issp.ge/blank-1',
        capabilities: 'https://www.issp.ge/blank-2',
        consultingAndIntegration: 'https://www.issp.ge/blank-9',
        securityOperationsCenter: 'https://www.issp.ge/blank-10',
        iSSPLabsAndResearch: 'https://www.issp.ge/issp',
        trainingCenter: 'https://www.issp.ge/blank-8',
        about: 'https://www.issp.ge/blank-3',
        missionAndValues: 'https://www.issp.ge/blank-6',
        vendorCatalog: 'https://www.issp.ge/blank-4',
        news: 'https://www.issp.ge/blank-7',
        country: regionsListConfig.GE,
    },
    KZ: {
        services: 'https://www.isspgroup.kz/uslugi',
        solutions: 'https://www.isspgroup.kz/resheniya',
        capabilities: 'https://www.isspgroup.kz/vozmozhnosti',
        consultingAndIntegration: 'https://www.isspgroup.kz/konsalting-i-integraciya',
        securityOperationsCenter: 'https://www.isspgroup.kz/centr-upravleniya-bezopasnostyu',
        iSSPLabsAndResearch: 'https://www.isspgroup.kz/laboratoriya-issp',
        trainingCenter: 'https://www.isspgroup.kz/trening-centr',
        about: 'https://www.isspgroup.kz/o-nas',
        missionAndValues: 'https://www.isspgroup.kz/missiya-i-cennosti',
        vendorCatalog: 'https://www.isspgroup.kz/katalog-vendorov',
        news: 'https://www.isspgroup.kz/novosti',
        country: regionsListConfig.KZ,
    },
    PL: {
        services: 'https://www.isspgroup.pl/uslugi',
        solutions: 'https://www.isspgroup.pl/rozwiazania',
        capabilities: 'https://www.isspgroup.pl/mozliwosci',
        consultingAndIntegration: 'https://www.isspgroup.pl/doradztwo-i-integracja',
        securityOperationsCenter: 'https://www.isspgroup.pl/centrum-operacji-bezpieczenstwa-soc',
        iSSPLabsAndResearch: 'https://www.isspgroup.pl/issp-labs',
        trainingCenter: 'https://www.isspgroup.pl/centrum-szkoleniowe',
        about: 'https://www.isspgroup.pl/o-nas',
        missionAndValues: 'https://www.isspgroup.pl/misja-i-wartosci',
        country: regionsListConfig.PL,
    },
};

const Navigation = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isCapabilitiesSubmenuOpened, setIsCapabilitiesSubmenuOpened] = useState(false);
    const [isAboutSubmenuOpened, setIsAboutSubmenuOpened] = useState(false);
    const [isRegionSubmenuOpened, setIsRegionSubmenuOpened] = useState(false);
    let country = 'CA';

    if (!window.location.href.includes('.ca')) {
        const location = useGeolocation();

        country = location.country
    };

    const navigationItemsConfig = navigationByCountryConfig[country] ? navigationByCountryConfig[country] : navigationByCountryConfig.GLOBAL;

    return (
        <nav className="navigation">
            <div className="navigation__content-wrapper">
                <a href="#">
                    <div className="navigation__logo"></div>
                </a>
                <div className={`navigation__content ${isCollapsed ? 'navigation__content_collapsed' : ''}`}>
                    <ul className="navigation__list">
                        <li>
                            <a href={navigationItemsConfig.services} className="navigation__item" target="_blank" rel="noopener noreferrer">
                                Services
                        </a>
                        </li>
                        <li>
                            <a href={navigationItemsConfig.solutions} className="navigation__item" target="_blank" rel="noopener noreferrer">
                                Solutions
                        </a>
                        </li>
                        <li className="navigation__item-with-sub-navigation">
                            <div>
                                <span
                                    onClick={() => setIsCapabilitiesSubmenuOpened(!isCapabilitiesSubmenuOpened)}
                                    className={`navigation__submenu-toggler ${isCapabilitiesSubmenuOpened ? 'navigation__submenu-toggler_active' : ''}`}
                                />
                                <a href={navigationItemsConfig.capabilities} className="navigation__item" target="_blank" rel="noopener noreferrer">
                                    Capabilities
                            </a>
                            </div>

                            <ul className={`navigation__submenu ${isCapabilitiesSubmenuOpened ? 'navigation__submenu_opened' : ''}`}>
                                <li>
                                    <a href={navigationItemsConfig.consultingAndIntegration} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                        Consulting &amp; Integration
                                </a>
                                </li>
                                <li>
                                    <a href={navigationItemsConfig.securityOperationsCenter} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                        Security Operations Center
                            </a>
                                </li>
                                <li>
                                    <a href={navigationItemsConfig.iSSPLabsAndResearch} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                        ISSP Labs and Research
                                </a>
                                </li>
                                <li>
                                    <a href={navigationItemsConfig.trainingCenter} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                        Training Center
                                </a>
                                </li>
                            </ul>
                        </li>
                        <li className="navigation__item-with-sub-navigation">
                            <div>
                                <span
                                    onClick={() => setIsAboutSubmenuOpened(!isAboutSubmenuOpened)}
                                    className={`navigation__submenu-toggler ${isAboutSubmenuOpened ? 'navigation__submenu-toggler_active' : ''}`}
                                />
                                <a href={navigationItemsConfig.about} className="navigation__item" target="_blank" rel="noopener noreferrer">
                                    About
                            </a>
                            </div>
                            <ul className={`navigation__submenu ${isAboutSubmenuOpened ? 'navigation__submenu_opened' : ''}`}>
                                <li>
                                    <a href={navigationItemsConfig.missionAndValues} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                        Mission and Values
                                </a>
                                </li>
                                {navigationItemsConfig.leadershipTeam && (
                                    <li>
                                        <a href={navigationItemsConfig.leadershipTeam} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                            Leadership Team
                                </a>
                                    </li>
                                )}
                                {navigationItemsConfig.vendorCatalog && (
                                    <li>
                                        <a href={navigationItemsConfig.vendorCatalog} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                            Vendor Catalog
                                </a>
                                    </li>
                                )}
                                {navigationItemsConfig.news && (
                                    <li>
                                        <a href={navigationItemsConfig.news} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                            News
                                </a>
                                    </li>
                                )}
                                {navigationItemsConfig.vacancies && (
                                    <li>
                                        <a href={navigationItemsConfig.vacancies} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                            Vacancies
                                </a>
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <a data-scroll href="#contactUs" className="navigation__item">
                                Contact
                            </a>
                        </li>
                        <li className="navigation__item-with-sub-navigation">
                            <div>
                                <span
                                    onClick={() => setIsRegionSubmenuOpened(!isRegionSubmenuOpened)}
                                    className={`navigation__submenu-toggler ${isRegionSubmenuOpened ? 'navigation__submenu-toggler_active' : ''}`}
                                />
                                <a href={navigationItemsConfig.country.link} className="navigation__item" target="_blank" rel="noopener noreferrer">
                                    | {navigationItemsConfig.country.text}
                                </a>
                            </div>
                            <ul className={`navigation__submenu ${isRegionSubmenuOpened ? 'navigation__submenu_opened' : ''}`}>
                                {
                                    Object.values(regionsListConfig)
                                        .filter((item) => item.text !== navigationItemsConfig.country.text)
                                        .map((item) => (
                                            <li key={item.text}>
                                                <a href={item.link} className="navigation__item navigation__submenu-item" target="_blank" rel="noopener noreferrer">
                                                    | {item.text}
                                                </a>
                                            </li>
                                        ))
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
                <Burger onClick={setIsCollapsed} />
            </div>
        </nav >
    );
};

export default Navigation;