import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AdvantageCard from './AdvantageCard';

import './VitalAreas.scss'

const VitalAreas = () => (
    <div className="vital-areas">
        <Grid style={{ position: 'relative' }}>
            <Row>
                <Col xs={12} md={4} className="vital-areas__card">
                    <AdvantageCard
                        iconType="key"
                        title="Safeguard IP rights"
                        subtitle="Secure critical business processes and protect confidential data"
                    />
                </Col>
                <Col xs={12} md={4} className="vital-areas__card vital-areas__card_with-margin">
                    <AdvantageCard
                        iconType="guard"
                        title="Meet investors' requirements"
                        subtitle="Investors won’t invest in what can easily be hacked, and they know how difficult it is to build a security team"
                    />
                </Col>
                <Col xs={12} md={4} className="vital-areas__card vital-areas__card_with-margin">
                    <AdvantageCard
                        iconType="lock"
                        title="Protect customers' data"
                        subtitle="When you get breached and personal data leaks, you’ll look bad and customers will be mad"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={9} lg={10}>
                    <p className="vital-areas__title text-lg text-bold">With ISSP SOC, you’ll have all vital areas of cybersecurity covered</p>
                    <p className="text-sm">and be sure you haven’t missed anything</p>
                </Col>
                <Col xs={12} md={8} mdOffset={4} lg={8} lgOffset={4} xl={9} xlOffset={2}>
                    <ul className="vital-areas__arrows-list">
                        <li className="vital-areas__arrows-list-item">
                            <p className="text-xs">Tailored incident detection, vulnerability management, and threat hunting services</p>
                        </li>
                        <li className="vital-areas__arrows-list-item">
                            <p className="text-xs">Customized based on analysis of your business, an audit of your cybersecurity posture, and compromise assessment results</p>
                        </li>
                        <li className="vital-areas__arrows-list-item">
                            <p className="text-xs">Augmented by continuous virtual CISO and consulting services so you can confidently make cybersecurity decisions</p>
                        </li>
                    </ul>
                </Col>
            </Row>
            <div className="vital-areas__laptop" />
        </Grid>
    </div >
);

export default VitalAreas;