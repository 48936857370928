import React from 'react';

import './SocCoversCard.scss';

type Props = {
    text: string,
};

const SocCoversCard = ({ text }: Props) => (
    <article className="soc-covers-card">
        <p className="text-xs text-white soc-covers__text">{text}</p>
    </article>
);

export default SocCoversCard;