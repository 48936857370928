import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import WhatYouGetCard from './WhatYouGetCard';
import LinkTo from '../LinkTo/LinkTo';

import './WhatYouGet.scss';

const SocCovers = () => (
    <Grid className="what-you-get">
        <Row>
            <Col xs={12} lg={9}>
                <h3 className="what-you-get__title text-lg text-bold">
                    What you get with ISSP SOC for startups
                </h3>
            </Col>
            <Col xs={12}>
                <p className="text-sm">
                    Access the leading enterprise technology stack and a skilled, highly motivated team without the hassles and costs associated with hiring, training, and retaining high-quality cybersecurity professionals
                </p>
            </Col>
        </Row>
        <Row>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="All the capabilities of an advanced enterprise security operations center (SOC)"
                    type="puzzle"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="Service quality controlled by a strict service-level agreement"
                    type="eye"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="Fast onboarding, with service starting in 1-3 weeks"
                    type="rocket"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="No capital expenditures needed"
                    type="pie"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="15-minute response time to critical Incidents"
                    type="ring"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="Pay as you grow"
                    type="plane"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="Ready for Azure, Google Cloud Platform, and Amazon Web Services"
                    type="cloud"
                />
            </Col>
            <Col xs={6} md={3}>
                <WhatYouGetCard
                    text="Starting as low as $980 per month"
                    type="wallet"
                />
            </Col>
        </Row>
        <Row className="what-you-get__links-wrapper">
            <Col xs={12} mdOffset={2} md={4}>
                <LinkTo href="#learnMore" text="I’m convinced" violet />
            </Col>
            <Col xs={12} md={4} className="what-you-get__second-link">
                <LinkTo href="#contactUs" text="I need more proof" />
            </Col>
        </Row>
    </Grid>
);

export default SocCovers;
