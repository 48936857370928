import React from 'react';

import './CyberThreatsCard.scss';

type Props = {
    text: string,
};

const CyberThreatsCard = ({ text }: Props) => (
    <article className="cyber-threats-card">
        <p className="text-xs text-white">{text}</p>
    </article>
);

export default CyberThreatsCard;