import React, { useState } from 'react';

import './RecentRealLifeCard.scss';

type Props = {
    cardNumber: number,
    text: string,
    readMoreContent: string,
    onReadMoreClick: (number) => void,
    readMoreContentShown: boolean,
};

const RecentRealLifeCard = ({ cardNumber, text, readMoreContent, onReadMoreClick, readMoreContentShown }: Props) => (
    <>
        <article className={`recent-real-life-card recent-real-life-card_${cardNumber}`}>
            <p className="text-xs text-white recent-real-life-card__text">{text}</p>
            <p
                className={`text-sm text-white recent-real-life-card__read-more ${readMoreContentShown ? 'recent-real-life-card__read-more_active' : ''}`}
                onClick={() => { onReadMoreClick(readMoreContentShown ? 0 : cardNumber); }}
            >
                Read more
            </p>
        </article>
        <div className={`text-xs text-white recent-real-life-card__read-more-content ${readMoreContentShown ? 'recent-real-life-card__read-more-content_shown' : ''}`}>
            {readMoreContent}
        </div>
    </>
);

export default RecentRealLifeCard;