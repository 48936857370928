import React from 'react';

import './HowExactlyCard.scss';

type Props = {
    type: 'aim' | 'pulse' | 'sunglasses',
    text: string,
    withArrow?: boolean,
};

const HowExactlyCard = ({ type, text, withArrow }: Props) => (
    <article className={`how-exactly-card how-exactly-card_icon-${type} ${withArrow ? 'how-exactly-card_with-arrow' : ''}`}>
        <p className="text-xs">{text}</p>
    </article>
);

export default HowExactlyCard;