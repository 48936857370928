import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import HowExactlyCard from './HowExactlyCard';
import LinkTo from '../LinkTo/LinkTo';

import './HowExactly.scss'

const HowExactly = () => (
    <div className="how-exactly">
        <Grid style={{ position: 'relative' }}>
            <div className="how-exactly__glitch" />
            <Row>
                <Col xs={12}>
                    <p className="text-lg text-bold how-exactly__title">
                        How exactly ISSP SOC works
                    </p>
                </Col>
                <Col xs={12}>
                    <p className="text-sm how-exactly__subtitle">
                        Our advanced threat detection service is built around detecting anomalies, identifying known adversarial techniques, and hunting for threats
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <HowExactlyCard
                        type="sunglasses"
                        text="The ISSP SOC Threat Hunting service requires the installation of harmless agents on your endpoints and servers that collect telemetry data related to your systems and users’ operations and then transfer it to ISSP SOC for abnormal activity detection."
                    />
                </Col>
                <Col xs={12} md={4}>
                    <HowExactlyCard
                        withArrow
                        type="pulse"
                        text="To detect anomalies in IT infrastructures, ISSP SOC creates behavior profiles for critical system parameters and monitors endpoints and servers for potential deviations. This approach allows you to detect both known and unknown threats."
                    />
                </Col>
                <Col xs={12} md={4}>
                    <HowExactlyCard
                        withArrow
                        type="aim"
                        text="Also, based on a set of rules developed using the MITRE ATT&CK™ framework and research carried out by ISSP Labs, SOC analysts and threat hunters look for indicators of known adversarial techniques."
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} mdOffset={4} lg={6} lgOffset={3} className="how-exactly__request-demo">
                    <div className="how-exactly__link-wrapper">
                        <LinkTo violet href="#learnMore" text="Request Demo" />
                    </div>
                </Col>
            </Row>
        </Grid>
    </div>
);

export default HowExactly;