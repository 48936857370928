import React from 'react';

import './CyberThreatsForm.scss';

const CyberThreatsForm = () => (
    <div id="learnMore" className="cyber-threats-form__wrapper">
        <p className="text-sm text-bold text-white cyber-threats-form__title">Ready to learn more?</p>
        <form
            className="cyber-threats-form"
            action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdjqp6DKebnzsSPtdGYr6s0wiey7KBfRiGDIIp61i47wYUrHQ/formResponse"
            target="_self"
            method="POST"
        >
            <fieldset className="cyber-threats-form__fieldset">
                <input className="cyber-threats-form__input" type="text" name="entry.152345433" placeholder="First Name" />
                <input className="cyber-threats-form__input" type="text" name="entry.1806423237" placeholder="Last Name" />
                <input className="cyber-threats-form__input" type="text" name="entry.1968508949" placeholder="Company Name" />
                <input className="cyber-threats-form__input" type="text" name="entry.998807703" placeholder="Phone Number" />
                <input className="cyber-threats-form__input" type="email" name="entry.1769660413" placeholder="Email*" required />
            </fieldset>
            <button className="cyber-threats-form__submit" type="submit">Contact me</button>
        </form>
    </div >
);

export default CyberThreatsForm;