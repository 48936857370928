import React from 'react';

import './FooterForm.scss';

const CyberThreatsForm = () => (
    <div id="contactUs" className="footer-form__wrapper">
        <form
            className="footer-form"
            action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSfJr7fpJ3ACDIxQ0URaBkhNh4xpcYiDnY-cRid-ijJkYtRdRA/formResponse"
            target="_self"
            method="POST"
        >
            <fieldset className="footer-form__fieldset">
                <input className="footer-form__input" type="text" name="entry.817026537" placeholder="First Name*" required />
                <input className="footer-form__input" type="text" name="entry.1429293927" placeholder="Last Name*" required />
                <input className="footer-form__input" type="text" name="entry.369813459" placeholder="Company Name*" required />
                <input className="footer-form__input" type="text" name="entry.472199694" placeholder="Role" />
                <input className="footer-form__input" type="email" name="entry.1744839573" placeholder="Work Email*" required />
                <input className="footer-form__input" type="text" name="entry.1987087401" placeholder="Phone Number" />
                <select name="entry.615632310" className="footer-form__select" defaultValue="Managed Security Services">
                    <option disabled>Please chose a team you’d like to get in touch with:*</option>
                    <option value="Managed Security Services">Managed Security Services</option>
                    <option value="Incident Response And Threat Hunting">Incident Response And Threat Hunting</option>
                    <option value="Consulting And Integration">Consulting And Integration</option>
                    <option value="Data Protection And Data Analytics">Data Protection And Data Analytics</option>
                    <option value="Training Programs">Training Programs</option>
                    <option value="Media Inquiries">Media Inquiries</option>
                    <option value="Media Inquiries">Not sure</option>
                </select>
                <textarea className="footer-form__textarea" rows={3} name="entry.956847343" placeholder="Please enter your questions or comments here:*" required />
            </fieldset>
            <button className="footer-form__submit" type="submit">Send</button>
        </form>
    </div >
);

export default CyberThreatsForm;