import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SocCoversCard from './SocCoversCard';
import LinkTo from '../LinkTo/LinkTo';

import './SocCovers.scss';

const SocCovers = () => (
    <div className="soc-covers">
        <Grid>
            <Row className="soc-covers__action-buttons-wrapper">
                <Col xs={12} mdOffset={4} md={4}>
                    <LinkTo href="#learnMore" text="I want this" violet />
                </Col>
                <Col xs={12} md={4} className="soc-covers__second-link">
                    <LinkTo href="#contactUs" text="I have questions" />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p className="text-white text-lg text-bold soc-covers__title">ISSP SOC covers your cybersecurity</p>
                </Col>
                <Col xs={12}>
                    <p className="text-white text-sm soc-covers__subtitle">while you focus on your main business processes</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Assign a virtual CISO with a real subject matter expert behind the scenes for guidance and support" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Conduct a compromise assessment to discover assets and define current threats and IT security flaws" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Study your cybersecurity posture, understand the threat models, and map attack surface" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Document the cybersecurity processes and our joint journey" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Define the SOC onboarding architecture" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Deploy pre-configured agents across PCs and servers and deploy connectors to pull IT logs from servers, apps, services, and cloud environments" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Establish a joint incident detection process (tailoring the playbooks)" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Kick off incident detection, vulnerability management, and threat hunting services" />
                </Col>
                <Col md={4} className="soc-covers__card-wrapper">
                    <SocCoversCard text="Get ongoing consulting and recommendations for continuous cybersecurity improvement" />
                </Col>
            </Row>
            <Row>
                <Col xs={12} mdOffset={3} md={6} className="soc-covers__third-link">
                    <LinkTo href="#learnMore" text="I need this" />
                </Col>
            </Row>
        </Grid>
    </div>
);

export default SocCovers;