import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CyberThreatsCard from './CyberThreatsCard';
import CyberThreatsForm from './CyberThreatsForm';

import './CyberThreats.scss'

const CyberThreats = () => (
    <div className="cyber-threats">
        <Grid>
            <Row>
                <Col xs={12}>
                    <p className="text-lg text-bold text-white cyber-threats__title">
                        Cyber threats are real
                    </p>
                </Col>
                <Col xs={12}>
                    <p className="text-sm text-white cyber-threats__subtitle">
                        Consider the following:
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={5} className="cyber-threats__cards">
                    <Row>
                        <Col xs={12} md={6} lg={12}>
                            <CyberThreatsCard
                                text="82% of the time, attackers can breach an organization within minutes"
                            />
                        </Col>
                        <Col xs={12} md={6} lg={12}>
                            <CyberThreatsCard
                                text="Supply chain cyber attacks leveraging small companies are booming"
                            />
                        </Col>
                        <Col xs={12} md={6} lg={12}>
                            <CyberThreatsCard
                                text="Exploits and malware can evade intrusion detection systems"
                            />
                        </Col>
                        <Col xs={12} md={6} lg={12}>
                            <CyberThreatsCard
                                text="Phishing is becoming the number one type of attack on SMBs"
                            />
                        </Col>
                        <Col xs={12} md={6} lg={12}>
                            <CyberThreatsCard
                                text="Negligent contractors can cause data breaches"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={6} lgOffset={1}>
                    <CyberThreatsForm />
                </Col>
            </Row>
        </Grid>
    </div>
);

export default CyberThreats;