import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import './Header.scss';

const Header = () => (
    <div className="header">
        <div className="header__content-wrapper">
            <Grid>
                <Row>
                    <Col xs={6} md={6}>
                        <div className="header__title-wrapper">
                            <h1 className="text-xl text-bold header__title">Cybersecurity for startups</h1>
                            <div className="text-md">
                                Connection to SOC within 2 weeks starting at $980
                        </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4} mdOffset={2}>
                        <div className="header__lock" />
                    </Col>
                </Row>
            </Grid>
        </div>
        <div className="header__glitch" />
    </div>
);

export default Header;