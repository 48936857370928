import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import WhyTheTailoredCard from './WhyTheTailoredCard';

import './WhyTheTailored.scss'

const WhyTheTailored = () => (
    <div className="why-the-tailored">
        <Grid className="why-the-tailored__content">
            <div className="why-the-tailored__safe" />
            <Row>
                <Col xs={6} md={8}>
                    <p className="text-lg text-bold why-the-tailored__title">
                        Why the tailored service approach works
                </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <WhyTheTailoredCard
                        title="Ongoing consulting"
                        subtitle="We realize it wouldn’t help if we rolled out a list of service options with price tags, and we understand you might have a tough time closing the expertise gap to make an informed decision in regard to what cybersecurity services you actually need."
                    />
                </Col>
                <Col xs={12}>
                    <WhyTheTailoredCard
                        title="Preliminary assessment"
                        subtitle="We will provide a consultation based on actual data from a preliminary study of your IT assets and business. We’ll get to know your cybersecurity posture, develop a solution architecture, and adjust your playbooks."
                    />
                </Col>
                <Col xs={12}>
                    <WhyTheTailoredCard
                        title="Informed decision"
                        subtitle="The final decision is yours — to opt for fixing the discovered problems in the short-term, mid-term, or long-term perspective or to take a fully informed decision to accept the corresponding risks."
                    />
                </Col>
            </Row>
        </Grid >
    </div>
);

export default WhyTheTailored;