import React from 'react';

import './WhyTheTailoredCard.scss';

type Props = {
    title: string,
    subtitle: string,
};

const WhyTheTailoredCard = ({ title, subtitle }: Props) => (
    <article className="why-the-tailored-card">
        <p className="text-sm text-bold why-the-tailored-card__title">{title}</p>
        <p className="text-xs">{subtitle}</p>
    </article>
);

export default WhyTheTailoredCard;