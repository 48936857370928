import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Marquee from "react-fast-marquee";

import './WhatTheySayTicker.scss';

const WhatTheySayTicker = () => (
    <div className="what-they-say-ticker">
        <Grid>
            <Row>
                <Col xs={12} md={8} mdOffset={2} lg={11} lgOffset={1}>
                    <p className="text-lg text-bold">
                        Stay focused on your key priorities
                    </p>
                </Col>
            </Row>
        </Grid>
        <div className="ticker__wrapper">
            <Marquee gradient={false} speed={120} className="ticker__content">
                <p className="text-md text-white ticker__item">
                    FUNDRAISING
                </p>
                <p className="text-md text-white ticker__item">
                    GO TO MARKET STRATEGY
                </p>
                <p className="text-md text-white ticker__item">
                    PRODUCT DEVELOPMENT
                </p>
                <p className="text-md text-white ticker__item">
                    REVENUE GROWTH
                </p>
                <p className="text-md text-white ticker__item">
                    SCALING
                </p>
            </Marquee>
        </div>
        <Grid>
            <Row>
                <Col xs={12} md={8} mdOffset={2} lg={11} lgOffset={1}>
                    <p className="text-lg text-bold">
                        We'll take care of your cybersecurity
                    </p>
                </Col>
            </Row>
        </Grid>
    </div>
);

export default WhatTheySayTicker;
