import React from 'react';

import './WhatYouGetCard.scss';

type Props = {
    text: string,
    type: 'cloud' | 'eye' | 'pie' | 'plane' | 'puzzle' | 'ring' | 'rocket' | 'wallet',
};

const SocCoversCard = ({ text, type }: Props) => (
    <article className={`what-you-get-card what-you-get-card_${type}`}>
        <p className="text-xs what-you-get__text">{text}</p>
    </article>
);

export default SocCoversCard;